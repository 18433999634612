<template>
  <div class="overflow-scroll">
    <div style="min-width: 1150px">
      <a-card>
        <div class="table-operator">
          <a-form layout="inline">
            <a-row :gutter="0" style="line-height: 3.5">
              <a-col :md="8" :sm="24" class="feeEditLabelWidth1">
                <a-form-item label="客户名称">
                  <a-input
                    v-model="queryParam.tenantName"
                    style="width: 240px"
                    placeholder="请输入"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24" class="feeEditLabelWidth1">
                <a-form-item label="费用类型">
                  <a-select
                    style="width: 240px"
                    v-model="queryParam.costType"
                    placeholder="请选择"
                  >
                    <a-select-option
                      v-for="item in costTypeList"
                      :key="item.id"
                      >{{ item.name }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24" class="stallsEditLabelWidth">
                <a-form-item label="是否已生成账单">
                  <a-select
                    style="width: 240px"
                    v-model="queryParam.isGenBill"
                    placeholder="请选择"
                    default-value="Y"
                  >
                    <a-select-option value="Y">是</a-select-option>
                    <a-select-option value="N">否</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24" class="feeEditLabelWidth1">
                <a-form-item label="审批状态">
                  <a-select
                    style="width: 240px"
                    v-model="queryParam.auditStatus"
                    placeholder="请选择"
                  >
                    <a-select-option
                      v-for="item in auditStatusList"
                      :key="item.id"
                      >{{ item.name }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="(!advanced && 8) || 24" :sm="24">
                <span
                  class="table-page-search-submitButtons"
                  :style="
                    (advanced && { float: 'right', overflow: 'hidden' }) || {}
                  "
                >
                  <a-button type="primary" @click="select">查询</a-button>
                  <a-button
                    style="margin-left: 8px"
                    @click="() => ((queryParam = {}), this.init())"
                    >重置</a-button
                  >
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>

        <div class="table-operator" style="margin-top: -16px">
          <a-button
            v-if="this.newHasRight === true"
            type="primary"
            icon="plus"
            @click="financeFeeNew"
            >新建</a-button
          >
        </div>
        <a-table
          rowKey="id"
          :pagination="pagination"
          :columns="columns"
          :dataSource="data"
          :scroll="{ x: 1600 }"
          @change="changePageSize"
        >
          <span slot="costType" slot-scope="text, record">
            {{ changeCostType(record.costType) }}
          </span>
          <span slot="action" slot-scope="text, record" class="blueColor">
            <a v-if="viewHasRight === true" @click="handleDetail(record)"
              >查看</a
            >&nbsp;&nbsp;
            <a v-if="editHasRight === true" @click="financeFeeEdit(record)"
              >修改</a
            >&nbsp;&nbsp;
            <a @click="financeFeeApproval(record)">审批</a>
          </span>
        </a-table>
      </a-card>
    </div>
  </div>
</template>

<script>
  
import Bus from "@/bus";
import moment from "moment";
import { defaultPagination } from "@/utils/pagination";
import axios from "axios";
import { mapGetters } from "vuex";
import { hasPermissions } from "../../utils/auth";
import { FacilityActions } from "../../utils/actions";

const data = [
  {
    id: "1",
    storeName: "John Brown",
    age: 32,
    style: "New York Park"
  },
  {
    id: "2",
    storeName: "Jim Green",
    age: 40,
    style: "London Park"
  }
];
export default {
  name: "financeFee",
  components: {},
  data() {
    return {
      data,
      showDetail: false,
      showEdit: false,
      showNew: false,
      advanced: false,
      thisdetail: "",
      queryParam: {
        costType: undefined,
        tenantName: "",
        isGenBill: undefined,
        auditStatus: undefined
      },
      pagination: defaultPagination(() => {}),
      // 表头
      columns: [
        {
          title: "客户ID",
          dataIndex: "tenantCode",
          key: "tenantCode",
          fixed: "left"
        },
        {
          title: "客户名称",
          dataIndex: "tenantName",
          key: "tenantName"
        },
        {
          title: "费用类型",
          // dataIndex: "costType",
          key: "costType",
          scopedSlots: { customRender: "costType" }
        },
        {
          title: "是否公摊",
          dataIndex: "isPublic",
          key: "isPublic"
        },
        {
          title: "费用金额",
          dataIndex: "costAmount",
          key: "costAmount"
        },
        {
          title: "是否已生成账单",
          dataIndex: "isGenBill",
          key: "isGenBill"
        },
        {
          title: "账单ID",
          dataIndex: "billCode",
          key: "billCode"
        },
        {
          title: "创建时间",
          dataIndex: "createTimeLg",
          key: "createTimeLg"
        },
        {
          title: "创建人",
          dataIndex: "creator",
          key: "creator"
        },
        {
          title: "审批状态",
          dataIndex: "auditStatus",
          key: "auditStatus"
        },
        {
          title: "操作",
          // dataIndex: "action",
          width: "200px",
          key: "action",
          fixed: "right",
          scopedSlots: { customRender: "action" }
        }
      ],
      //费用类型
      costTypeList: [
        { id: 1, name: "水费" },
        { id: 2, name: "电费" },
        { id: 3, name: "燃气" },
        { id: 4, name: "房租" },
        { id: 5, name: "出租租金押金" },
        { id: 6, name: "入会费" },
        { id: 7, name: "燃气押金" },
        { id: 8, name: "质量保证金" },
        { id: 9, name: "商户减免" },
        { id: 10, name: "商户扣除" },
        { id: 11, name: "改造费" },
        { id: 12, name: "增容费" },
        { id: 13, name: "燃气改造费" },
        { id: 14, name: "其他费用" },
        { id: 15, name: "首期款定金" }
      ],
      //审批状态
      auditStatusList: [
        { id: 1, name: "无需审批" },
        { id: 2, name: "待审批" },
        { id: 3, name: "已审批" }
      ]
    };
  },
  created() {
    this.init();
    Bus.$on("getTarget", target => {
      this.init();
    });
  },
  computed: {
    ...mapGetters("users", ["authenticatedUser"]),
    newHasRight() {
      return hasPermissions(
        [FacilityActions.FEES_CREATE],
        this.authenticatedUser.actions
      );
    },
    viewHasRight() {
      return hasPermissions(
        [FacilityActions.FEES_READ],
        this.authenticatedUser.actions
      );
    },
    editHasRight() {
      return hasPermissions(
        [FacilityActions.FEES_EDIT],
        this.authenticatedUser.actions
      );
    }
  },
  methods: {
    moment,
    changePageSize(val) {
      this.init(val.current, val.pageSize);
    },
    changeCostType(val) {
      let value;
      if (val) {
        this.costTypeList.forEach(item => {
          if (item.id == val) {
            value = item.name;
          }
        });
        return value;
      }
    },
    init(current, pageSize) {
      axios({
        url:   this.$baseUrl + "tenantCost/list",
        method: "GET",
        withCredentials: true,
        params: {
          rows: pageSize || 20,
          page: current || 1,
          ...this.queryParam
        }
      }).then(res => {
        if (res.data.rows) {
          res.data.rows.forEach(item => {
            item.createTimeLg = item.createTimeLg
              ? moment(item.createTimeLg).format("YYYY-MM-DD")
              : "";
            item.isPublic = item.isPublic == "N" ? "否" : "是";
            item.isGenBill = item.isGenBill == "N" ? "否" : "是";
            item.auditStatus =
              item.auditStatus == 1
                ? "无需审批"
                : item.auditStatus == 2
                ? "待审批"
                : item.auditStatus == 3
                ? "已审批"
                : "";
          });
          this.data = res.data.rows;
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.pagination = pagination;
        }
      });
    },
    select() {
      this.init();
    },
    financeFeeNew(val) {
      this.$router.push({
        path: "/finance/financeFee/financeFeeNew",
        query: { thisdetailId: val.id }
      });
    },
    handleDetail(val) {
      this.$router.push({
        path: "/finance/financeFee/financeFeeDetail",
        query: { thisdetailId: val.id }
      });
    },
    financeFeeEdit(val) {
      this.$router.push({
        path: "/finance/financeFee/financeFeeNew",
        query: { thisdetailId: val }
      });
    },
    financeFeeApproval() {},
    canceladd() {
      this.showNew = false;
      this.showDetail = false;
      this.init();
    }
  }
};
</script>

<style scoped>
.table-operator {
  margin-bottom: 18px;
}
</style>
